import { mapGetters } from "vuex";
import Service from "@/services/Service";

export default {
  data() {
    return {
      capabilities: [],
      capabilitiesFiltered: {},
      capabilityMenu: [],
    };
  },
  computed: {
    ...mapGetters({
      capability: "getCapability",
    }),
  },
  async beforeMount() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const {
          data: {
            data: { items },
          },
        } = await Service.all();
        this.capabilities = [...items];
        this.capabilityMenu = this.capabilities.map((capabilty, key) => {
          return {
            title: capabilty.title,
            active: key === 0,
          };
        });
        this.capabilitiesFiltered = this.capabilities[0];
        this.$store.commit(
          "SET_CAPABILITIES",
          this.capabilities.map((capability) => {
            const { id, title, slug } = capability;
            return {
              id,
              title,
              slug,
            };
          })
        );
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    toggle(key, title, status) {
      this.capabilityMenu.map((item) => (item.active = false));
      this.capabilityMenu[key].active = !status;
      this.capabilitiesFiltered = this.capabilities[key];
    },
  },
};

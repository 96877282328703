import axios from "axios";
let api = process.env.VUE_APP_API_BASE_URL;

let Api = axios.create({
  baseURL: api,
});

Api.defaults.withCredentials = true;

Api.interceptors.response.use(
  (response) => response,
  async (error) => error
);

const getAuthorizationHeader = () => {
  return {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
};

const getRequest = async function (payload) {
  return Api({
    url: payload.endpoint,
    method: "GET",
    headers: getAuthorizationHeader(),
  });
};

const postRequest = async function (payload) {
  let data = payload.data;
  return Api({
    url: payload.endpoint,
    method: payload.method || "POST",
    headers: getAuthorizationHeader(),
    data: data,
  });
};

const deleteRequest = async function (payload) {
  return Api({
    url: payload.endpoint,
    method: "DELETE",
    headers: getAuthorizationHeader(),
  });
};

export default {
  getRequest,
  postRequest,
  deleteRequest,
};

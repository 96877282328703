//IMPORT CSS
import("@/assets/css/main.css");
import("@/assets/css/responsive.css");
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import { vuetifyProTipTap } from "@/plugins/tiptap";
import { VueHeadMixin, createHead } from "@unhead/vue";
const head = createHead();
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = true;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(fas);
library.add(fab);

const app = createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(head)
  .mixin(VueHeadMixin)
  .use(vuetify)
  .use(vuetifyProTipTap);

router.isReady().then(() => app.mount("#app"));

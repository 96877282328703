import Api from "@/services/Api";
const basePath = "/project";
const all = (payload) => {
  const path = `${basePath}/all`;
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const get = () => {
  return Api.getRequest({
    endpoint: `${basePath}/get`,
  });
};

const pageDetails = () => {
  return Api.getRequest({
    endpoint: `${basePath}/page`,
  });
};

export default {
  all,
  get,
  pageDetails,
};

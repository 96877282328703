<template>
  <!-- section testimonial start here -->
  <section class="section-testimonial">
    <div class="container">
      <div class="title-box" v-if="testimonial">
        <h2 class="primary-heading" v-html="testimonial.title"></h2>
        <p v-html="testimonial.description"></p>
      </div>
      <div class="slide-container">
        <div class="slide-content">
          <div class="slide-wrapper swiper-wrapper">
            <swiper
              :modules="modules"
              :slides-per-view="1"
              :space-between="50"
              :pagination="{ clickable: true }"
            >
              <swiper-slide
                v-for="(testimonial, key) in testimonials"
                :key="key"
              >
                <div class="review-slide">
                  <div class="image-content">
                    <div class="customer-image">
                      <img
                        :src="getFile(testimonial.image_url)"
                        alt="customer-img"
                        class="customer-img"
                      />
                    </div>
                  </div>
                  <div class="customer-content">
                    <h3>{{ testimonial.name }}</h3>
                    <span v-if="testimonial.designation">
                      {{ testimonial.designation }}
                    </span>
                    <div class="star">
                      <font-awesome-icon
                        icon="star"
                        v-for="item in Number(testimonial.rating)"
                        :key="item"
                      />
                    </div>
                    <p v-html="testimonial.description"></p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </section>
  <!-- section testimonial end here -->
</template>

<script>
// import Swiper core and required modules
import { Pagination } from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Testimonial from "@/services/Testimonial";
import ImageUrlPathHandler from "@/components/mixins/FileUrlPathHandler";
import { mapGetters } from "vuex";

export default {
  name: "TestimonialComponent",
  components: { Swiper, SwiperSlide },
  mixins: [ImageUrlPathHandler],
  data() {
    return {
      modules: [Pagination],
      testimonials: [],
    };
  },
  computed: {
    ...mapGetters({
      testimonial: "getTestimonial",
    }),
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const {
          data: {
            data: { items },
          },
        } = await Testimonial.all();
        this.testimonials = [...items];
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
};
</script>

<style scoped></style>

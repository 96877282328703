<template>
  <!-- about-us section start here -->
  <section class="about-us-section">
    <div class="container">
      <div class="about-container grid grid-call-2">
        <div class="about-content">
          <div class="title-box" v-if="about">
            <h3 class="tersiary-heaading">{{ about.title }}</h3>
            <h2 class="primary-heading" v-html="about.sub_title"></h2>
          </div>
          <p v-if="about">
            {{ about.description || "" }}
          </p>

          <router-link to="/about-us" class="btn about-btn">
            About Us <font-awesome-icon icon="fa-solid fa-arrow-right" />
          </router-link>
        </div>

        <div class="company-stats grid grid-call-2">
          <div class="work-statistics">
            <div
              class="statistics-details"
              v-for="(item, key) in aboutRight.slice(0, 2)"
              :key="key"
            >
              <span>{{ item.title }}</span>
              <h4>{{ item.sub_title }}</h4>
              <p>
                {{ item.description }}
              </p>
            </div>
          </div>
          <div class="work-statistics center-stats">
            <div
              class="statistics-details"
              v-for="(item, key) in aboutRight.slice(2, 4)"
              :key="key"
            >
              <span>{{ item.title }}</span>
              <h4>{{ item.sub_title }}</h4>
              <p>
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- about-us section end here -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AboutComponent",
  computed: {
    ...mapGetters({
      about: "getAboutSection" || null,
      aboutRight: "getAboutSectionRight" || null,
    }),
  },
};
</script>

<style scoped></style>

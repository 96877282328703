import Api from "@/services/Api";
const basePath = "/setting";
const get = () => {
  return Api.getRequest({
    endpoint: `${basePath}/get`,
  });
};

export default {
  get,
};

<template>
  <head-component :meta="{}" />
  <hero-component />
  <about-component />
  <service-component />
  <why-us-component />
  <quote-component />
  <technology-component />
  <faq-component />
  <product-component />
  <get-in-touch-component />
  <testimonial-component />
</template>

<script>
import TestimonialComponent from "@/components/home/TestimonialComponent";
import ServiceComponent from "@/components/home/ServiceComponent";
import ProductComponent from "@/components/home/ProductComponent";
import FaqComponent from "@/components/home/FaqComponent";
import TechnologyComponent from "@/components/home/TechnologyComponent";
import QuoteComponent from "@/components/home/QuoteComponent";
import WhyUsComponent from "@/components/home/WhyUsComponent";
import GetInTouchComponent from "@/components/home/GetInTouchComponent";
import AboutComponent from "@/components/home/AboutComponent";
import HeroComponent from "@/components/home/HeroComponent";
import HeadComponent from "@/components/meta/HeadComponent";
export default {
  name: "HomeView",
  components: {
    HeadComponent,
    HeroComponent,
    AboutComponent,
    GetInTouchComponent,
    WhyUsComponent,
    QuoteComponent,
    TechnologyComponent,
    FaqComponent,
    ProductComponent,
    ServiceComponent,
    TestimonialComponent,
  },
};
</script>

<style lang="scss">
.vuetify-pro-tiptap-editor__content.markdown-theme-default {
  color: inherit !important;
}
.vuetify-pro-tiptap-editor__content.markdown-theme-default h1:before,
.vuetify-pro-tiptap-editor__content.markdown-theme-default h2:before,
.vuetify-pro-tiptap-editor__content.markdown-theme-default h3:before,
.vuetify-pro-tiptap-editor__content.markdown-theme-default h4:before,
.vuetify-pro-tiptap-editor__content.markdown-theme-default h5:before,
.vuetify-pro-tiptap-editor__content.markdown-theme-default h6:before {
  padding-right: 0 !important;
  color: inherit !important;
  content: "" !important;
}
.vuetify-pro-tiptap-editor__content.markdown-theme-default b,
.vuetify-pro-tiptap-editor__content.markdown-theme-default strong {
  color: inherit !important;
}
</style>

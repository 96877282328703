<template>
  <!-- newsletter section start here -->
  <section class="newsletter-section">
    <div class="container">
      <div class="news_letter_inner">
        <div class="news_title"><p>Newsletter SignUp!</p></div>
        <div class="news_letter_form">
          <form>
            <input
              type="email"
              required="required"
              name=""
              id="email"
              placeholder="Your Email Address"
              value=""
            />
          </form>
        </div>
        <a href="javascript:" class="btn contact-btn">
          Subscribe Now
          <i class="fa fa-angle-double-right"></i>
        </a>
      </div>
    </div>
  </section>
  <!-- newsletter section end here -->
</template>

<script>
export default {
  name: "NewsletterComponent",
};
</script>

<style scoped></style>

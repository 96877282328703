import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const dataState = createPersistedState({
  paths: [
    "setting",
    "capabilities",
    "pages",
    "getHeroSection",
    "getAboutSection",
    "getAboutSectionRight",
    "getCapability",
    "getWhyUs",
    "getWhyUsRight",
    "getBudget",
    "getTechnology",
    "getFaq",
    "getProduct",
    "getContact",
    "getTestimonial",
  ],
});
export default createStore({
  state: {
    pages: [],
    setting: {},
    capabilities: [],
  },
  getters: {
    getHeroSection(state) {
      return state.pages.find((item) => item.section_type === "hero");
    },
    getAboutSection(state) {
      return state.pages.find((item) => item.section_type === "about");
    },
    getAboutSectionRight(state) {
      return state.pages.filter((item) => item.section_type === "about_right");
    },
    getCapability(state) {
      return state.pages.find((item) => item.section_type === "capability");
    },
    getWhyUs(state) {
      return state.pages.find((item) => item.section_type === "why_us");
    },
    getWhyUsRight(state) {
      return state.pages.filter((item) => item.section_type === "why_us_right");
    },
    getBudget(state) {
      return state.pages.find((item) => item.section_type === "budget");
    },
    getTechnology(state) {
      return state.pages.find((item) => item.section_type === "technology");
    },
    getFaq(state) {
      return state.pages.find((item) => item.section_type === "faq");
    },
    getProduct(state) {
      return state.pages.find((item) => item.section_type === "product");
    },
    getContact(state) {
      return state.pages.find((item) => item.section_type === "contact");
    },
    getTestimonial(state) {
      return state.pages.find((item) => item.section_type === "testimonial");
    },
  },
  mutations: {
    SET_SETTING: function (state, payloads) {
      state.setting = payloads;
    },
    SET_PAGES: function (state, payloads) {
      state.pages = payloads;
    },
    SET_CAPABILITIES: function (state, payloads) {
      state.capabilities = payloads;
    },
  },
  actions: {},
  modules: {},
  plugins: [dataState],
});

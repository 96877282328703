<template>
  <!-- why-us section  start here -->
  <section class="why-us-section">
    <div class="container">
      <div class="title-box">
        <h2 v-if="whyUs" class="primary-heading" v-html="whyUs.title"></h2>
        <p v-if="whyUs" v-html="whyUs.sub_title"></p>
      </div>
      <div class="why-us-container grid grid-call-2">
        <div class="why-us-image-box">
          <img :src="require('@/assets/img/specialized/why.png')" alt="" />
          <span><i class="fa-solid fa-quote-right"></i></span>
          <p v-if="whyUs">
            {{ whyUs.description }}
          </p>
          <router-link to="#" class="btn contact-btn">
            Get In Touch <font-awesome-icon icon="arrow-right" />
          </router-link>
        </div>
        <div class="why-us-content">
          <div class="why-details" v-for="(item, key) in whyUsRight" :key="key">
            <h2 class="secondary-primary-heading">{{ item.title }}</h2>
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- why-us section end here -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WhyUsComponent",
  computed: {
    ...mapGetters({
      whyUs: "getWhyUs",
      whyUsRight: "getWhyUsRight",
    }),
  },
};
</script>

<style scoped></style>

<template>
  <Head>
    <title>{{ getMeta.title }}</title>
    <meta name="description" :content="getMeta.description" />
    <meta name="keywords" :content="getMeta.keywords" />
    <link rel="canonical" :href="getMeta.link" />

    <!-- Schema.org markup for Google+ -->
    <meta itemprop="name" :content="`Deveable - ${getMeta.keywords}`" />
    <meta itemprop="description" :content="getMeta.description" />

    <!-- Twitter Card data -->

    <meta name="twitter:creator" content="@deveable" />
    <meta name="twitter:site" content="Deveable" />
    <meta name="twitter:title" :content="`Deveable - ${getMeta.keywords}`" />
    <meta name="twitter:description" :content="getMeta.description" />
    <meta name="twitter:url" :content="getMeta.link" />
    <meta name="twitter:image" :content="getMeta.imageUrl" />
    <!-- Twitter summary card with large image must be at least 280x150px -->
    <!-- Open Graph data -->
    <meta property="og:title" :content="`Deveable - ${getMeta.keywords}`" />
    <meta property="og:description" :content="getMeta.description" />
    <meta property="og:url" :content="getMeta.link" />
    <meta property="og:image" :content="getMeta.imageUrl" />
    <meta property="og:site_name" content="Deveable" />
  </Head>
</template>
<script>
import { Head } from "@unhead/vue/components";
export default {
  name: "HeadComponent",
  components: { Head },
  data() {
    return {
      title: "Digital reality with deveable",
      description:
        "Deveable are talented individuals who want to make a difference, people who like to go the extra mile to ensure quality",
      keywords:
        "web development, UI/UX design, web design, figma to HTML, PSD to HTML and image to HTML",
      link: "https://deveable.com/",
      imageUrl: require("@/assets/logo.png"),
    };
  },
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getMeta() {
      return {
        title: this.meta.title ? this.meta.title : this.title,
        description: this.meta.description
          ? this.meta.description
          : this.description,
        keywords: this.meta.keywords ? this.meta.keywords : this.keywords,
        link: this.meta.keywords ? this.meta.keywords : this.link,
        imageUrl: this.meta.imageUrl ? this.meta.imageUrl : this.imageUrl,
      };
    },
  },
};
</script>

<style scoped></style>

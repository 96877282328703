import HomeView from "@/views/HomeView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("@/views/AboutView"),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("@/views/ContactUs"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy"),
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: () => import("@/views/TermsOfService"),
  },
  {
    path: "/service/:slug",
    name: "SingleService",
    component: () => import("@/views/SingleServiceView"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("@/views/FaqView"),
  },
  {
    path: "/our-works",
    name: "OurWorks",
    component: () => import("@/views/OurWorksView"),
  },
  {
    path: "/case-study",
    name: "CaseStudy",
    component: () => import("@/views/CaseStudyView"),
  },
  {
    path: "/quote",
    name: "Quote",
    component: () => import("@/views/QuoteView"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/404"),
  },
];

export default routes;

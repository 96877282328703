import Api from "@/services/Api";
const basePath = "/testimonial";
const all = () => {
  return Api.getRequest({
    endpoint: `${basePath}/all`,
  });
};

export default {
  all,
};

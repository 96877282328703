<template>
  <!-- footer start here -->
  <footer>
    <div class="container">
      <div class="footer-container grid grid-call-4">
        <div class="footer-content-box">
          <a href="index.html">
            <img
              :src="require('@/assets/img/logo/deveable-logo2.png')"
              alt=""
            />
          </a>
          <div class="footer-content">
            <p v-if="setting.description" v-html="setting.description"></p>
            <div class="team-contact">
              <a
                v-if="setting.facebook"
                :href="setting.facebook"
                target="_blank"
              >
                <font-awesome-icon icon="fa-brands fa-facebook" />
              </a>
              <a v-if="setting.twitter" :href="setting.twitter" target="_blank">
                <font-awesome-icon icon="fa-brands fa-x-twitter" />
              </a>
              <a v-if="setting.youtube" :href="setting.youtube" target="_blank">
                <font-awesome-icon icon="fa-brands fa-youtube" />
              </a>
              <a v-if="setting.skype" :href="setting.skype" target="_blank">
                <font-awesome-icon icon="fa-brands fa-skype" />
              </a>
              <a
                v-if="setting.linkedin"
                :href="setting.linkedin"
                target="_blank"
              >
                <font-awesome-icon icon="fa-brands fa-linkedin" />
              </a>
            </div>
          </div>
        </div>

        <div class="footer-content-box">
          <h2>Company</h2>
          <div class="footer-content">
            <ul>
              <li v-for="(item, key) in company" :key="key">
                <router-link :to="item.path">
                  <font-awesome-icon icon="angle-right" />
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-content-box">
          <h2>Services</h2>
          <div class="footer-content">
            <ul>
              <li v-for="(service, key) in services" :key="key">
                <router-link :to="`/service/${service.slug}`">
                  <font-awesome-icon icon="angle-right" />
                  {{ service.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-content-box">
          <h2>Office Address</h2>
          <div class="footer-content">
            <ul>
              <li>
                <a href="#javascript" v-if="setting">
                  <font-awesome-icon icon="globe" />
                  <span v-html="setting.address"></span>
                </a>
              </li>
              <li v-if="setting.phone">
                <a :href="`tel:${setting.phone}`">
                  <font-awesome-icon icon="fa fa-phone" /> {{ setting.phone }}
                </a>
              </li>
              <li v-if="setting.primary_email">
                <a :href="`mailto:${setting.primary_email}`">
                  <font-awesome-icon icon="envelope" />{{
                    setting.primary_email
                  }}
                </a>
              </li>
              <li v-if="setting.secondary_email">
                <a :href="`mailto:${setting.secondary_email}`">
                  <font-awesome-icon icon="envelope" />{{
                    setting.secondary_email
                  }}
                </a>
              </li>
              <li v-if="setting.skype">
                <a href="#javascript">
                  <font-awesome-icon :icon="['fab', 'skype']" />
                  {{ setting.skype }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="reserved-policy">
      <div class="container">
        <div class="reserve-policy-content">
          <div class="reserve-right">
            © {{ new Date().getFullYear() }} Deveable - All Rights Reserved
          </div>
          <div class="policy">
            <span>
              <router-link to="privacy-policy"> Privacy Policy </router-link>
            </span>
            <span>
              <router-link to="terms-of-service">
                Terms of Service
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>

    <a href="#" @click="scrollToTop" class="back-home">
      <font-awesome-icon icon="angles-up" />
    </a>
  </footer>
  <!-- footer end here -->
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppFooter",
  data() {
    return {
      company: [
        { title: "About Us", path: "/about-us" },
        { title: "Our Works", path: "/our-works" },
        { title: "Case Study", path: "/case-study" },
        { title: "Blog", path: "#" },
        { title: "Contact Us", path: "/contact-us" },
      ],
    };
  },
  computed: {
    ...mapState({
      services: (state) => state.capabilities,
      setting: (state) => state.setting,
    }),
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <!-- service-section section start here -->
  <section class="service-section">
    <div class="container">
      <div class="title-box" v-if="capability">
        <h3 class="tersiary-heaading">{{ capability.title }}</h3>
        <h2 class="primary-heading" v-html="capability.sub_title"></h2>
      </div>

      <div class="service-container grid grid-call-3">
        <div
          class="service-content"
          :key="key"
          v-for="(capability, key) in capabilities"
        >
          <div class="service-image">
            <img :src="getFile(capability.image_url)" :alt="capability.title" />
          </div>
          <h2 class="secondary-primary-heading">{{ capability.title }}</h2>
          <p v-html="capability.excerpt"></p>
          <router-link :to="`/service/${capability.slug}`">
            Read More <font-awesome-icon icon="arrow-right" />
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- service-section section start here -->
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FileUrlPathHandler from "@/components/mixins/FileUrlPathHandler";
import CapabilityHandler from "@/components/mixins/CapabilityHandler";
export default {
  name: "ServiceComponent",
  components: { FontAwesomeIcon },
  mixins: [FileUrlPathHandler, CapabilityHandler],
};
</script>

<style scoped></style>

<template>
  <!-- Technology-section  start here -->
  <section class="technology-section">
    <div class="container">
      <div class="title-box" v-if="technology">
        <h2 class="primary-heading" v-html="technology.title"></h2>
        <p>
          {{ technology.description }}
        </p>
      </div>
      <div class="technology-container">
        <div class="technology-name">
          <ul>
            <li
              :class="{ active: menu.active }"
              v-for="(menu, key) in technologyMenu"
              :key="key"
              @click="toggle(key, menu.name, menu.active)"
            >
              {{ menu.name }}
            </li>
          </ul>
        </div>
        <div class="technology-details">
          <div class="grid grid-call-4">
            <div
              class="use-experties-text"
              :key="key"
              v-for="(tech, key) in technologyGroup"
            >
              <img :src="getFile(tech.image_url)" :alt="tech.name" />
              {{ tech.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Technology-section  end here -->
</template>

<script>
import Technology from "@/services/Technology";
import FileUrlPathHandler from "@/components/mixins/FileUrlPathHandler";
import { mapGetters } from "vuex";

export default {
  name: "TechnologyComponent",
  mixins: [FileUrlPathHandler],
  data() {
    return {
      technologies: [],
      technologyMenu: [],
      technologyGroup: "",
    };
  },
  computed: {
    ...mapGetters({
      technology: "getTechnology",
    }),
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const {
          data: {
            data: { items },
          },
        } = await Technology.all();
        this.technologies = [...items];
        const techMap = [...new Set(items.map((item) => item.type))];
        this.technologyMenu = techMap.map((name, key) => {
          return {
            name,
            active: key === 0,
          };
        });
        this.technologyGroup = this.technologies.filter(
          (technology) => technology.type === this.technologyMenu[0].name
        );
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    toggle(key, name, status) {
      this.technologyMenu.map((item) => (item.active = false));
      this.technologyMenu[key].active = !status;
      this.technologyGroup = this.technologies.filter(
        (tech) => tech.type === name
      );
    },
  },
};
</script>

<style scoped></style>

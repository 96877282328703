<template>
  <!-- project-discussion-section section start here -->
  <section class="project-discussion-section">
    <div class="container">
      <div class="title-box" v-if="budget">
        <h2 class="primary-heading" v-html="budget.title"></h2>
        <h3 class="tersiary-heaading">
          {{ budget.sub_title }}
        </h3>
      </div>

      <div class="project-discussion-container grid grid-call-2">
        <div class="project-discussion-image-box">
          <img
            :src="require('@/assets/img/specialized/project-discussion.png')"
            alt=""
          />
          <div class="footer-content">
            <ul>
              <li>
                <a href="#javascript" v-if="setting">
                  <font-awesome-icon icon="globe" />
                  <span
                    style="text-transform: capitalize"
                    v-html="setting.address"
                  ></span>
                </a>
              </li>
              <li v-if="setting.phone">
                <a :href="`tel:${setting.phone}`">
                  <font-awesome-icon icon="fa fa-phone" /> {{ setting.phone }}
                </a>
              </li>
              <li v-if="setting.primary_email">
                <a :href="`mailto:${setting.primary_email}`">
                  <font-awesome-icon icon="envelope" />{{
                    setting.primary_email
                  }}
                </a>
              </li>
              <li v-if="setting.secondary_email">
                <a :href="`mailto:${setting.secondary_email}`">
                  <font-awesome-icon icon="envelope" />{{
                    setting.secondary_email
                  }}
                </a>
              </li>
              <li v-if="setting.skype">
                <a href="#javascript">
                  <font-awesome-icon :icon="['fab', 'skype']" />
                  {{ setting.skype }}
                </a>
              </li>
            </ul>
          </div>

          <div class="benifit">
            <h2 class="secondary-primary-heading">& What's you will get :</h2>
            <ul>
              <li>
                <i class="fa-regular fa-circle"></i>
                Project Consulting by Experts
              </li>
              <li>
                <i class="fa-regular fa-circle"></i>
                Excellent Customer Support
              </li>
              <li>
                <i class="fa-regular fa-circle"></i>
                On-Time Project Delivery
              </li>
            </ul>
          </div>
        </div>
        <div class="project-discussion-content">
          <h2 class="primary-heading">
            Let's Discuss Your <span>Project</span>
          </h2>
          <p>
            We're a friendly & reliable Web Agency with a proven track record
            that gets the job done, on time and on budget.
          </p>

          <form action="">
            <div class="contact-form-input">
              <input type="text" name="" id="" placeholder="First Name*" />
              <input type="text" name="" id="" placeholder="Last Name*" />
            </div>
            <div class="contact-form-input">
              <input
                type="text"
                name=""
                id=""
                placeholder="Company/Organization*"
              />
              <input type="url" name="" id="" placeholder="Website" />
            </div>
            <div class="contact-form-input">
              <input type="email" name="" id="" placeholder="Email Address*" />
              <input type="tel" name="" id="" placeholder="Phone*" />
            </div>

            <div class="contact-form-input">
              <select name="" id="">
                <option value="">Services*</option>
                <option value="">WordPress Developement</option>
                <option value="">graphic Design</option>
                <option value="">web-development</option>
                <option value="">web design</option>
                <option value="">Ui/Ux Design</option>
                <option value="">Software Developement</option>
                <option value="">App Developement</option>
              </select>
              <select name="" id="">
                <option value="">Budget</option>
                <option value="">$500+</option>
                <option value="">$1,500-$2,000</option>
                <option value="">$2,000-$5,000</option>
                <option value="">$5,000-$10,000</option>
                <option value="">$10,000-$20,000</option>
                <option value="">$20,000-$50,000</option>
                <option value="">$50,000+</option>
              </select>
            </div>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Tell us about your business"
            ></textarea>
          </form>
          <a href="javascript:" class="btn contact-btn">
            Submit <font-awesome-icon icon="arrow-right" />
          </a>
          <router-link to="/contact-us" class="btn contact-btn">
            Talk To Our Expert <font-awesome-icon icon="arrow-right" />
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- project-discussion-section section end here -->
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters, mapState } from "vuex";
export default {
  name: "QuoteComponent",
  components: { FontAwesomeIcon },
  computed: {
    ...mapState({
      setting: (state) => state.setting,
    }),
    ...mapGetters({
      budget: "getBudget" || null,
    }),
  },
};
</script>

<style scoped></style>

<template>
  <div class="navbar">
    <div class="container">
      <nav class="navlist">
        <div class="mobile-navbar">
          <span class="mobile-bar"><font-awesome-icon icon="bars" /></span>
        </div>
        <div class="logo-box">
          <router-link to="/">
            <img
              :src="require('@/assets/img/logo/deveable-logo.png')"
              alt="Logo image"
            />
          </router-link>
        </div>
        <div class="main-nav-link">
          <ul class="nav-link-li">
            <div class="mobile-navbar">
              <span class="mobile-close">
                <i class="fa-solid fa-xmark"></i>
              </span>
            </div>
            <li>
              <router-link :to="{ path: '/', name: 'home' }">Home</router-link>
            </li>
            <li><router-link to="/about-us">About us</router-link></li>
            <li>
              <router-link :to="{ path: '/faq', name: 'FAQ' }">FAQ</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/our-works', name: 'OurWorks' }">
                Our Works
              </router-link>
            </li>
            <li><router-link to="#">Blog</router-link></li>
            <li class="dropdown" v-show="capabilities.length > 0">
              Services
              <font-awesome-icon icon="angle-down" />

              <ul class="dropdown-li-list">
                <li v-for="(capability, key) in capabilities" :key="key">
                  <router-link :to="`/service/${capability.slug}`">
                    {{ capability.title }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{ path: '/contact-us', name: 'ContactUs' }">
                Contact Us
              </router-link>
            </li>
          </ul>
          <div class="quote-box">
            <router-link
              :to="{ path: '/quote', name: 'Quote' }"
              class="btn contact-btn"
            >
              Quote Now
            </router-link>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- navbar end here -->
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AppHeader",
  computed: {
    ...mapState({
      capabilities: (state) => state.capabilities,
    }),
  },
  methods: {
    scroll(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div id="app">
    <head-component :meta="meta || {}" />
    <app-header />
    <main>
      <router-view />
      <newsletter-component />
    </main>
    <app-footer />
  </div>
</template>
<script>
import AppHeader from "@/layouts/AppHeader";
import AppFooter from "@/layouts/AppFooter";
import NewsletterComponent from "@/components/home/NewsletterComponent";
import Home from "@/services/Home";
import HeadComponent from "@/components/meta/HeadComponent";
import SettingHandler from "@/components/mixins/SettingHandler";

export default {
  name: "App",
  mixins: [SettingHandler],
  components: { HeadComponent, NewsletterComponent, AppFooter, AppHeader },
  async beforeMount() {
    await this.loadMeta();
    await this.loadSetting();
  },
  data() {
    return {
      meta: {},
    };
  },
  methods: {
    async loadMeta() {
      try {
        const {
          data: {
            data: { item },
          },
        } = await Home.all({});
        const {
          seo_title: title,
          seo_keywords: keywords,
          seo_description: description,
          page_details: pages,
        } = item;

        Object.assign(this.meta, { title, keywords, description });
        this.$store.commit("SET_PAGES", pages);

        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
};
</script>

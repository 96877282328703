import Settings from "@/services/Settings";

export default {
  methods: {
    async loadSetting() {
      try {
        const {
          data: {
            data: { item },
          },
        } = await Settings.get();
        this.$store.commit("SET_SETTING", item);
        // eslint-disable-next-line no-empty
      } catch (errors) {}
    },
  },
};

<template>
  <!-- frequently Asked quesion section  start here -->

  <section class="faq-section" id="faq">
    <div class="container">
      <div class="title-box" v-if="faq">
        <h2 class="primary-heading" v-html="faq.title"></h2>
      </div>
      <div class="accordian">
        <div
          class="accordian-content-box"
          :class="{ active: faq.active }"
          v-for="(faq, key) in faqs"
          :key="key"
          @click="toggle(key, faq.active)"
        >
          <div class="accordian-heading">
            {{ faq.question }}
            <span>
              <font-awesome-icon icon="angle-down" />
            </span>
          </div>
          <div class="accordian-text">
            <p>
              {{ faq.answer }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- frequently Asked quesion section end here -->
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Faq from "@/services/Faq";
import { mapGetters } from "vuex";
export default {
  name: "FaqComponent",
  components: { FontAwesomeIcon },
  data() {
    return {
      faqs: [],
    };
  },
  computed: {
    ...mapGetters({
      faq: "getFaq",
    }),
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const {
          data: {
            data: { items },
          },
        } = await Faq.all();
        this.faqs = [...items].map((item, key) => {
          return {
            ...item,
            active: key === 0,
          };
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    toggle(key, status) {
      this.faqs.map((item) => (item.active = false));
      this.faqs[key].active = !status;
    },
  },
};
</script>

<style scoped></style>

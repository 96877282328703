<template>
  <!-- hero section start here -->
  <section class="hero-section">
    <div class="container">
      <div class="hero-container">
        <div class="hero-content" v-if="hero">
          <h1 class="hero-heading" v-html="hero.title"></h1>
          <p class="hero-text">
            {{ hero.description }}
          </p>
          <router-link to="/contact-us" class="btn contact-btn">
            Let's Talk <font-awesome-icon icon="arrow-right" />
          </router-link>
        </div>
        <div class="hero-image-box">
          <img :src="require('@/assets/img/hero-img/hero-img.svg')" alt="" />
        </div>
      </div>
    </div>
  </section>
  <!-- hero section end here -->
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters } from "vuex";
export default {
  name: "HeroComponent",
  components: { FontAwesomeIcon },
  computed: {
    ...mapGetters({
      hero: "getHeroSection",
    }),
  },
};
</script>

<style scoped></style>

import Api from "@/services/Api";
const basePath = "/service";
const all = () => {
  return Api.getRequest({
    endpoint: `${basePath}/all`,
  });
};

const get = (slug) => {
  return Api.getRequest({
    endpoint: `${basePath}/${slug}`,
  });
};

export default {
  all,
  get,
};

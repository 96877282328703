<template>
  <!-- ready made product section start here -->
  <section class="ready-template-section">
    <div class="container">
      <div class="title-box" v-if="product">
        <h2 class="primary-heading" v-html="product.title"></h2>
        <p>
          {{ product.description }}
        </p>
      </div>
      <div class="ready-template-container grid grid-call-3">
        <div
          class="ready-template-content"
          :key="key"
          v-for="(product, key) in products"
        >
          <div class="ready-template-img-box">
            <img :src="getFile(product.image_url)" :alt="product.title" />
          </div>
          <h2 class="secondary-primary-heading">
            {{ product.title }}
          </h2>
          <div class="button-box">
            <router-link to="/contact-us" class="btn buy-btn">
              Buy Now
            </router-link>
            <a
              :href="product.live_link"
              class="btn live-view-btn"
              target="_blank"
            >
              Live View
            </a>
          </div>
        </div>
      </div>
      <div class="center-btn-box" v-show="products.length > 0">
        <router-link to="/our-works" class="btn contact-btn">
          See All Our Works
          <font-awesome-icon icon="arrow-right" />
        </router-link>
      </div>
    </div>
  </section>
  <!-- ready made product section start here -->
</template>

<script>
import Project from "@/services/Project";
import FileUrlPathHandler from "@/components/mixins/FileUrlPathHandler";
import { mapGetters } from "vuex";

export default {
  name: "ProductComponent",
  mixins: [FileUrlPathHandler],
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters({
      product: "getProduct",
    }),
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const {
          data: {
            data: { items },
          },
        } = await Project.get();
        this.products = [...items];
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
};
</script>

<style scoped></style>

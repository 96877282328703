<template>
  <!-- get-all-service section start here -->
  <section class="get-all-service-section">
    <div class="container">
      <div class="get-all-service-container grid grid-call-2">
        <div class="get-all-service-image-box">
          <img
            :src="require('@/assets/img/specialized/all-service.png')"
            alt="Service"
          />
        </div>
        <div class="get-all-service-content" v-if="contact">
          <h2 class="primary-heading" v-html="contact.title"></h2>
          <p>
            {{ contact.description }}
          </p>
          <router-link to="/contact-us" class="btn contact-btn">
            Get In Touch <font-awesome-icon icon="arrow-right" />
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- get-all-service section end here -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GetInTouchComponent",
  computed: {
    ...mapGetters({
      contact: "getContact",
    }),
  },
};
</script>

<style scoped></style>
